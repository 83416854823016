import { Options } from 'p-retry';

declare global {
  interface Window {
    __retrier:
      | null
      | (<T>(
          input: (attemptCount: number) => T | PromiseLike<T>,
          options?: Options | undefined
        ) => Promise<T>);
  }
}

if (typeof window !== 'undefined') {
  window.__retrier = null;
}

export async function getRetrier() {
  if (typeof window === 'undefined') {
    return (await import('p-retry')).default;
  }

  if (!window.__retrier) {
    window.__retrier = (await import('p-retry')).default;
  }

  return window.__retrier;
}

export async function doNotRetry<T>(
  input: (attemptCount: number) => T | PromiseLike<T>
): Promise<T> {
  return input(1);
}
