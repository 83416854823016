import {
  Reducer,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Options } from 'p-retry';
import { getRetrier } from '@resources/js/helpers/getRetrier';

export type FetchParameters = Pick<
  RequestInit,
  'headers' | 'credentials' | 'signal'
> & {
  url: string;
  method?: 'get' | 'post' | 'put' | 'delete';
  body?: unknown;
  retryOptions?: Options;
};

export type DoFetch = (newParameters: FetchParameters | string) => void;

export const idleFetchStatus = {
  isIdle: true,
  isSuccess: undefined,
  isNotModified: undefined,
  isLoading: undefined,
  isError: undefined,
};

export const successFetchStatus = {
  isIdle: undefined,
  isSuccess: true,
  isNotModified: undefined,
  isLoading: undefined,
  isError: undefined,
};

export type FetchStatus =
  | typeof idleFetchStatus
  | typeof successFetchStatus
  | {
      isIdle?: undefined;
      isSuccess: true;
      isNotModified: true;
      isLoading?: undefined;
      isError?: undefined;
    }
  | {
      isIdle?: undefined;
      isSuccess?: undefined;
      isNotModified?: undefined;
      isLoading: true;
      isError?: undefined;
    }
  | {
      isIdle?: undefined;
      isSuccess?: undefined;
      isNotModified?: undefined;
      isLoading?: undefined;
      isError: true;
      errorMessage: string;
    };

export type FetchState<T> = {
  data?: T;
  status: FetchStatus;
};
type FetchAction<T> = {
  type:
    | 'FETCH_INIT'
    | 'FETCH_SUCCESS'
    | 'FETCH_FAILURE'
    | 'FETCH_RESET'
    | 'FETCH_NOT_MODIFIED';
  data?: T;
  payload?: T;
  status?: number;
};

type PayloadWithMessage<T> = T & { message: string };

function dataFetchReducer<T>(
  state: FetchState<T>,
  action: FetchAction<T>
): FetchState<T> {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        status: { isLoading: true },
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        data: action.payload,
        status: successFetchStatus,
      };
    case 'FETCH_NOT_MODIFIED':
      return {
        ...state,
        data: state.data,
        status: { isSuccess: true, isNotModified: true },
      };
    case 'FETCH_FAILURE': {
      let errorMessage = trans('global.error_but_try_again');
      if (action.status === 401 || action.status === 403)
        errorMessage = trans('global.not_authorized');
      if (
        action.status === 400 &&
        (action.payload as PayloadWithMessage<T>)?.message
      )
        errorMessage = (action.payload as PayloadWithMessage<T>).message;

      return {
        ...state,
        status: {
          isError: true,
          errorMessage,
        },
      };
    }
    case 'FETCH_RESET': {
      return {
        data: action.data,
        status: idleFetchStatus,
      };
    }
    default:
      throw new Error();
  }
}

const csrfToken = (
  global.document?.head.querySelector('meta[name="csrf-token"]') as
    | HTMLMetaElement
    | undefined
)?.content;

export function maybePrefixForTest(url: string): string {
  if (process.env.NODE_ENV !== 'test' || url.includes('http')) {
    return url;
  }

  return `http://test${url}`;
}

export type UseFetchReturn<T> = [FetchState<T>, DoFetch, (data?: T) => void];

/**
 * Hook containing common data fetching logic. Example usage:
 *   JS:
 *     const [{ data, status }, doFetch] = useFetch();
 *       'data' is the data returned by the API or the data set by initialData.
 *       'status' is an object with booleans representing the progress of the fetch call ('isIdle', 'isLoading', 'isSuccess', 'isError')
 *       'doFetch' is a function that takes a URL as a parameter and will fetch that URL
 *   JSX:
 *     Example usage of state in JSX:
 *       {status.isIdle && trans.update}
 *       {status.isLoading && trans.queueing}
 *       {status.isSuccess && trans.inQueue}
 *       {status.isSuccess && status.isNotModified} // The `X-Content-Hash` header matched and a HTTP response with a status of 304 was received
 *       {status.isError && status.errorMessage} // status.errorMessage will show messages based on 401/403/500/400 (returned validation message in the latter case)
 * @param {FetchParameters | string} [initialParameters] - Set this if you want to fetch data immediately
 * @param {any} [initialData] - Set this if you want to assign interim data immediately, before any fetching has occurred or finished
 * @returns {[*, function(FetchParameters | string): void, function(): void]}
 */
function useFetch<T>(
  initialParameters?: FetchParameters | string,
  initialData?: T
): UseFetchReturn<T> {
  const parametersRef = useRef(initialParameters);
  const contentHash = useRef<string | null>(null);
  const [requestId, setRequestId] = useState(0);

  const [state, dispatch] = useReducer<Reducer<FetchState<T>, FetchAction<T>>>(
    dataFetchReducer,
    {
      data: initialData,
      status: idleFetchStatus,
    }
  );

  useEffect(() => {
    if (!parametersRef.current) return;
    const parameters = parametersRef.current;

    let didCancel = false;

    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' });

      const headers = new Headers(
        typeof parameters !== 'string' ? parameters.headers : undefined
      );
      if (csrfToken) {
        headers.append('X-CSRF-TOKEN', csrfToken);
      }
      if (typeof parameters !== 'string' && parameters.body) {
        headers.append('Content-Type', 'application/json');
      }
      if (contentHash.current) {
        headers.append('X-Content-Hash', contentHash.current);
      }

      let url: string | null = null;
      const options: RequestInit = { headers };

      if (typeof parameters === 'object') {
        url = maybePrefixForTest(parameters.url);

        options.method =
          parameters.method ?? (parameters.body ? 'post' : 'get');
        options.credentials = parameters.credentials ?? 'same-origin';
        options.signal = parameters.signal;

        if (parameters.body) {
          options.body = JSON.stringify(parameters.body);
        }
      } else {
        url = maybePrefixForTest(parameters);
      }

      if (!url) {
        return;
      }

      let result: Response | null = null;

      try {
        const retryOptions = getRetryOptions(parameters);
        const retries = retryOptions.retries ?? 0;

        const retrier = await getRetrier();
        result = await retrier(async (attemptCount) => {
          const response = await fetch(
            url ?? '', // shouldn't be null as it's checked above, but needed for type-checking here
            options
          );

          if (!response.ok && attemptCount < retries + 1) {
            throw new Error(`HTTP status ${response.status}`);
          }

          return response;
        }, retryOptions);

        if (didCancel) {
          return;
        }

        if (result.ok) {
          contentHash.current = result.headers?.get('X-Content-Hash');
          dispatch({
            type: 'FETCH_SUCCESS',
            payload: result.status === 204 ? undefined : await result.json(),
            status: result.status,
          });
        } else if (result.status === 304) {
          dispatch({
            type: 'FETCH_NOT_MODIFIED',
            status: result.status,
          });
        } else {
          let payload;
          try {
            payload = await result.json();
          } catch (e) {
            payload = undefined;
          }

          dispatch({
            type: 'FETCH_FAILURE',
            payload,
            status: result.status,
          });
        }
      } catch (e) {
        if (didCancel) {
          return;
        }

        if (e instanceof Error && result) {
          const text = result.bodyUsed ? '' : await result.text?.();
          let errorText = `Original exception: ${e.message}`;

          if (text) {
            errorText += `

Response body:

${text}`;
          }
          console.error(new Error(errorText));
        }

        dispatch({ type: 'FETCH_FAILURE' });
      }
    };

    fetchData().catch(console.error);

    return () => {
      didCancel = true;
    };
  }, [requestId]);

  const doFetch = useCallback((newParameters: FetchParameters | string) => {
    parametersRef.current = newParameters;
    setRequestId((previous) => previous + 1);
  }, []);

  const resetFetch = useCallback(
    (data?: T) => {
      dispatch({
        type: 'FETCH_RESET',
        data: data ?? initialData,
      });
    },
    [initialData]
  );

  return [state, doFetch, resetFetch];
}

export const fetchStatusPropType = PropTypes.shape({
  isIdle: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
});

export const doNotRetry: Options = {
  retries: 0,
};
export const retryOnceSlowly: Options = {
  retries: 1,
  minTimeout: 3000,
};
export const retryTwiceSlowly: Options = {
  retries: 2,
  minTimeout: 3000,
};

function getRetryOptions(parameters: string | FetchParameters): Options {
  if (typeof parameters === 'string') {
    return doNotRetry;
  }

  const retryOptions = parameters.retryOptions ?? doNotRetry;

  if (!retryOptions.retries) {
    retryOptions.retries = 0;
  }

  return retryOptions;
}

export default useFetch;
